// export default {
//   pageHeroV1: {
//     container: {
//       height: '75vh',
//       width: '100%',
//       backgroundSize: 'cover',
//       backgroundPosition: 'center',
//       alignItems: 'center',
//       justifyContent: 'center',
//     },

//     content: {
//       flexDirection: 'column',
//       color: 'white',
//       textAlign: 'center',
//       h1: {
//         fontSize: ['2.5rem', '', '4.5rem'],
//         marginBottom: 1,
//         letterSpacing: '3px',
//         borderColor: 'secondary',
//         // backgroundColor: 'rgba(0,0,0,0.35)',
//         padding: '1rem',
//         fontFamily: 'heading',
//         fontWeight: 'normal',
//         color: 'light',
//       },
//       h2: {
//         marginBottom: 4,
//       },
//     },
//   },
// }
export default {
  pageHeroV1: {
    container: {
      height: '65vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'center',
      justifyContent: 'center'
    },

    content: {
      flexDirection: 'column',
      marginTop: '15rem',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['2.5rem', '', '4.5rem'],
        letterSpacing: '3px',
        textTransform: 'capitalize',
        borderColor: 'secondary',
        backgroundColor: 'rgb(201,81,2, .7)',
        padding: '2rem',
        fontFamily: 'body',
        fontWeight: 'normal',
        color: 'light',
        border: 'solid 5px',
        borderColor: 'primaryLight'
      }
    }
  }
}
