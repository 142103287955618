export default {
  heading: 'Caveat, sans-serif',
  subheading: 'Caveat, sans-serif',
  body: 'Open Sans, sans-serif',
  body2: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: '',
  googleFonts: [
    'Caveat:400,600',
    'Italianno',
    'Open Sans:400,500,600,700,800,900'
    // 'Josefin Sans:100,200,300,400,500,600,700,800,900',
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
